html {
  height: 100vh;
  width: 100vw;
}

body {
  height: 100%;
  width: 100%;
  background-color: rgb(18, 18, 18);
}

#root {
  height: 100%;
  width: 100%;
}

.react-multi-carousel-list {
  height: 100%;
  width: 600px;
  float: right;
  border-radius: 6px;
}

ul.react-multi-carousel-dot-list {
  position: static;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 12px;
}

/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

div.snackbar-container-root {
  top: 65px !important;
}
