.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (min-width: 900px) {
  .react-horizontal-scrolling-menu--arrow-left {
    width: 40px;
    margin-right: 8px;
  }

  .react-horizontal-scrolling-menu--arrow-right {
    width: 40px;
    margin-left: 8px;
  }
}
